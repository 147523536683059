.adm-sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 255px;
    height: 100%;
    background-color: #3c4b64;
    z-index: 1030;
    transition: .3s;
    transform: translateX(-100%);
}

.adm-sidebar.show{
    transform: translateX(0%);
}

.adm-sidebar-brand {
    display: flex;
    flex: 0 0 4rem;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.87);
    background: #00001533;
}

.adm-sidebar-nav {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
}

.adm-nav-item {
    display: list-item;
    text-align: -webkit-match-parent;
    transition: .3s;
    background-color: transparent;
}

.adm-nav-item:hover{
    background-color: rgba(255, 255, 255, 0.055);
}

.adm-nav-icon{
    flex: 0 0 4rem;
    height: 1.25rem;
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    fill: currentColor;
    transition: inherit;
}

.adm-nav-link {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0.8rem;
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    white-space: nowrap;
    background: transparent;
    border: transparent;
    border-radius: 0;
    transition: background 0.15s ease, color 0.15s ease;
}

.adm-nav-link:hover{
    color: rgb(255, 255, 255);
}

.adm-sidebar-close{
    width: 30px;
    height: 35px;
    background-color: #3c4b64;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-right: -30px;
    border-radius: 0 10px 10px 0;
    font-size: 16x;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {
    .adm-sidebar {
        width: 80% !important;
    }
}