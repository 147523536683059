.btnShowFeedback {
    background-color: orangered;
    width: auto;
    height: auto;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 100px;
    margin-right: -40px;
    padding: 3px 20px;
    border-radius: 5px 5px 0px 0px;
    color: white;
    transform: rotate(-90deg) translateY(75%);
    z-index: 2;
    transition: .3s;
    cursor: pointer;
}

.btnShowFeedback:hover {
    transform: rotate(-90deg) translateY(0%);
}

.containerFeedback {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    z-index: 4;
    align-items: center;
    justify-content: center;
    transition: .3s;
    visibility: hidden;
    opacity: 0;
}

.containerFeedback.active {
    visibility: visible;
    opacity: 1;
}

.contentFeedback {
    background-color: white;
    width: 40%;
    border-radius: 8px;
}

.headerFeedback {
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 10px;
}

.headerFeedback>span {
    font-size: 12px;
    display: block;
    margin-top: -8px;
}

.bodyFeedback {
    padding-left: 15px;
    padding-right: 15px;
}

.btn-warning {
    background-color: orangered !important;
    border-color: orangered !important;
    color: white !important;
}

@media (max-width: 991.98px) {
    .contentFeedback {
        width: 60% !important;
    }
}

@media (max-width: 767.98px) {
    .contentFeedback {
        width: 93% !important;
    }
}