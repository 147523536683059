.weather-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .12);
    z-index: 2;
    margin-top: 75px;
    margin-left: 65px;
    transition: .3s;
    transform: translateX(-120%);
}

.weather-container.active {
    transform: translateX(0%);
}

.weather-toggle {
    background-color: #FFFFFF;
    border: none;
    outline: none;
    position: absolute;
    z-index: 2;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: orangered;
    color: white;
    margin-top: 90px;
    margin-left: 13px;
}

/* .weather-toggle{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    margin-right: -50px;
    background-color: rgb(255, 255, 255);
    padding: 3px 15px;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .12);
} */

.weather-content {
    width: 100%;
    height: 100%;
    padding: 25px;
}

.air-text-green{
    color: #607631;
}

.air-bg-green{
    background-color: #a8e05f;
}

.air-text-yellow{
    color: #8c6c1d;
}

.air-bg-yellow{
    background-color: #fdd64b;   
}

.air-text-red{
    color: #942431;
}

.air-bg-red{
    background-color: #fe6a69;
}

.air-text-orange{
    color: #974a20;
}

.air-bg-orange{
    background-color: #ff9b57;
}

.air-text-purple{
    color: #543b63;
}

.air-bg-purple{
    background-color: #a97abc;
}

.air-text-maroon{
    color: #573344;
}

.air-bg-maroon{
    background-color: #a87383;
}

.air-label{
    font-size: 12px;
}

.section-air{
    display: flex;
    border-radius: 6px;
    margin-bottom: 2rem;
    overflow: hidden;
}

.air-icon{
    width: 25%;
    background-color: rgba(0, 0, 0, .08);
    display: flex;
}

.air-face{
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
}

.air-info{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.air-info-first{
    margin-top: -10px;
}

.air-info-first > span:nth-child(1){
    font-size: 2rem;
    margin-right: 10px;
}

.air-info-first > span:nth-child(2){
    font-size: 12px;
}

.air-info-second{
    background-color: white;
    padding: 0px 5px;
    border-radius: 3px;
    font-size: 12px;
}

.section-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -20px;
}

.weather-data {
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: column;
}

.weather-temp {
    font-size: 5rem;
    text-align: center;
}

.weather-data>span {
    margin-top: -20px;
    font-size: 13px;
}

.section-2 {
    margin-top: 30px;
}

.weather-info {
    font-size: 14px;
    font-weight: 500;
}

.weather-info-data {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.col-same {
    width: 50%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
}

.col-same>span {
    margin-left: 5px;
    font-size: 14px;
}

.col-same>span>div:last-child {
    margin-top: -5px;
}

@media (max-width: 767.98px) {
    .weather-container {
        width: 100% !important;
    }
}