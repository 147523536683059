.btnShowSidebar {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    margin-top: 15px;
    margin-right: 15px;
    height: 35px;
    width: 35px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: orangered;
    color: white;
}

.btnShowSidebar:hover {
    background-color: rgb(218 218 218);
}

.containerSidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 22%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 2;
    transition: .3s;
    transform: translateX(105%);
    box-sizing: border-box;
    margin-top: 55px;
}

.containerSidebar.active {
    transform: translateX(0%);
}

.headerSidebar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
}

.btnHideSidebar {
    background-color: orangered;
    color: white;
    height: 30px;
    width: 30px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 15px;
}

.titleHeaderSidebar {
    color: orangered;
    margin: 0;
}

.bodySidebar {
    width: 100%;
    height: calc(100% - 60px);
    box-sizing: border-box;
    padding: 0px 15px 15px 15px;
}

.bodySidebar-styleMap {
    display: flex;
    flex-wrap: wrap;
    height: 120px;
}

.bodySidebar-dataMap {
    height: calc(100% - 230px);
    overflow: auto;
}

.bodySidebar-dataMap::-webkit-scrollbar {
    width: 5px;
}

.bodySidebar-dataMap::-webkit-scrollbar-track {
    background-color: #DDDDDD;
    border-radius: 1000px;
}

.bodySidebar-dataMap::-webkit-scrollbar-thumb {
    background-color: orangered;
    border-radius: 1000px;
}

/* Image Sponsor */
.containerSponsor {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.listSponsor {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-right: 50px;
    margin-left: 50px;
}

.imgLogo {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px;
    height: 50px;
}

.imgSponsor {
    height: 24px;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 5px;
}

.esri-view-surface--inset-outline {
    outline: none !important;
    border: none !important;
}

/* .esri-ui-top-left{
    margin-top: 200px!important;
} */

.esri-ui-top-right {
    margin-right: 45px !important;
}

.ctg-header {
    width: 100%;
    font-size: 14px;
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: .2s;
}

.ctg-header:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.ctg-body {
    margin-left: 10px;
    display: none;
    transition: .3s;
    margin-bottom: 10px;
}

.ctg-body.active {
    display: block;
}

.modal {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 991.98px) {
    .containerSidebar {
        width: 33% !important;
    }
}

@media (max-width: 767.98px) {
    .containerSidebar {
        width: 80% !important;
    }
}